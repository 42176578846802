
import "@/assets/styles.css";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormDefinitive,
    SettingsFormInterface,
    ResponseDataString as rds
} from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption, noData } from "@/utils";
@Component({
    components: {
        FilterForm: () => import("@/pages/components/forms/FilterFormV2.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        Echart: () => import("@/components/charts/eChart.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(Nombre) {
            if (Nombre == "Total") return "light-grey";
            else return "white";
        }
    }
})
export default class Energy extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormDefinitive = {
        company: "",
        client: "",
        installation: "",
        elements: [],
        inversors: [],
        dates: [],
        services: [],
        comparative: false,
        days: "10",
        window: "1h",
        noHijos: false,
        valid: true
    };
    settingsModalShow: boolean | null = false;
    settingsForm: SettingsFormInterface = {
        valid: false,
        tolerance: 0.05
    };
    alturaCW = 500;
    alturaPie = 400;
    initEchart = Eoption;
    showFilter = true;
    showAll = false;
    start = "";
    start1 = "";
    start2 = "";
    end = "";
    end1 = "";
    end2 = "";
    first = false;
    window = "15minutos";
    CSV = "";
    itemsPerPage = 6;
    activeEnergyTotal1Chart: any = [];
    activeEnergyTotal2Chart: any = [];
    activeEnergyComparative1Chart: any = [];
    activeEnergyComparative2Chart: any = [];
    activeEnergy1Chart: any = [];
    activeEnergy2Chart: any = [];
    heatMapEnergy1Chart: any = [];
    heatMapEnergy2Chart: any = [];
    generation1Chart: any = [];
    generation2Chart: any = [];
    generation1InversorChart: any = [];
    generation2InversorChart: any = [];
    heatmap1: any = [];
    heatmap2: any = [];
    servicios1: any = [];
    servicios2: any = [];
    parc1: any = [];
    parc2: any = [];
    errors: ChartError[] = [];
    columnsTot = [
        {
            text: "Elemento",
            sortable: true,
            value: "element"
        },
        {
            text: "Energía [kWh]",
            value: "energy",
            align: "right"
        }
    ];
    consumptionTot1 = [];
    consumptionTot2 = [];
    generationTot1 = [];
    generationTot2 = [];
    consumption1 = [];
    consumption2 = [];
    columns = [
        {
            text: "Fecha",
            sortable: true,
            value: "date"
        },
        {
            text: "Energía [kWh]",
            value: "energy",
            align: "right"
        }
    ];
    columnsConsumo = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Total del Intervalo[m³]",
            sortable: true,
            value: "tot",
            align: "right"
        }
    ];
    columnsNitrogen = [
        {
            text: "Elemento",
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo[m³]",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo[m³]",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio[m³]",
            sortable: true,
            value: "avg",
            align: "right"
        },
        {
            text: "Total del Intervalo[m³]",
            sortable: true,
            value: "tot",
            align: "right"
        }
    ];

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }
    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);
        this.showAll = false;
        this.activeEnergyTotal1Chart = noData;
        this.activeEnergyTotal2Chart = noData;
        this.heatmap1 = noData;
        this.heatmap2 = noData;
        this.activeEnergyComparative1Chart = noData;
        this.activeEnergyComparative2Chart = noData;
        this.activeEnergy1Chart = noData;
        this.activeEnergy2Chart = noData;
        this.consumptionTot1 = [];
        this.consumptionTot2 = [];
        this.consumption1 = [];
        this.consumption2 = [];
        this.generationTot1 = [];
        this.generationTot2 = [];
        this.generation1Chart = noData;
        this.generation2Chart = noData;
        this.generation1InversorChart = noData;
        this.generation2InversorChart = noData;
        this.parc1 = [];
        this.parc2 = [];
        this.servicios1 = [];
        this.servicios2 = [];
        this.CSV = "";

        if (this.filterForm.dates) {
            if (this.filterForm.comparative && this.filterForm.days) {
                this.end1 = new Date(
                    new Date(this.filterForm.dates[0]).setDate(
                        new Date(this.filterForm.dates[0]).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.end2 = new Date(
                    new Date(this.filterForm.dates[1]).setDate(
                        new Date(this.filterForm.dates[1]).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.start1 = new Date(this.filterForm.dates[0])
                    .toISOString()
                    .split("T")[0];
                this.start2 = new Date(this.filterForm.dates[1])
                    .toISOString()
                    .split("T")[0];
                this.end = this.end2;
                this.start = this.start2;
            } else {
                const aux0 = new Date(this.filterForm.dates[0]);
                let aux1;
                if (this.filterForm.dates.length > 1) {
                    aux1 = new Date(this.filterForm.dates[1]);
                    if (aux1 > aux0) {
                        this.end = this.filterForm.dates[1];
                        this.start = this.filterForm.dates[0];
                    } else {
                        this.end = this.filterForm.dates[0];
                        this.start = this.filterForm.dates[1];
                    }
                } else {
                    this.start = this.filterForm.dates[0];
                    this.end = this.start;
                }
            }
        }
        if (this.filterForm.elements) {
            let change = this.filterForm.elements.filter(x => {
                return x["NpaCons"] == 0 ? true : false;
            }).length;
            if (change > 6) {
                // const aux = document.getElementById("Energy-activeEnergyTotal");

                change = Math.floor((change - 2) / 4);
                this.alturaPie = 400 + change * 100;
                this.alturaCW = 500 + change * 100;
                this.itemsPerPage = Math.round(5 + change * 2.3);
                //     let aux = document.getElementById("Energy-activeEnergyTotal");
                //     if (aux != null) {
                //         aux.style.height =
                //             Number(400 + change * 200).toString() + "px;";
                //     }
                //     aux = document.getElementById("CW-Energy-activeEnergyTotal");
                //     if (aux != null) {
                //         aux.style.height =
                //             Number(500 + change * 200).toString() +
                //             "px !important;";
                //     }
                // } else {
                //     this.itemsPerPage = 6;
                //     let aux = document.getElementById("Energy-activeEnergyTotal");
                //     if (aux != null) {
                //         aux.style.height = "400 px;";
                //     }
                //     aux = document.getElementById("CW-Energy-activeEnergyTotal");
                //     if (aux != null) {
                //         aux.style.height = "500px !important;";
                //     }
            } else {
                this.itemsPerPage = 6;
                this.alturaCW = 500;
                this.alturaPie = 400;
            }
        }
        do {
            let JSOON: any[] = [];
            if (this.filterForm.inversors) {
                if (this.filterForm.inversors.length) {
                    JSOON = [
                        {
                            elements: this.filterForm.elements,
                            cantEle: this.filterForm.CantEle,
                            inversors: this.filterForm.inversors,
                            cantInv: this.filterForm.CantInv
                        }
                    ];
                } else {
                    JSOON = [
                        {
                            elements: this.filterForm.elements,
                            inversors: [],
                            cantEle: 1000,
                            cantInv: 1000
                        }
                    ];
                }
            } else {
                JSOON = [
                    {
                        elements: this.filterForm.elements,
                        inversors: [],
                        cantEle: 1000,
                        cantInv: 1000
                    }
                ];
            }
            const EnergyHorizReq = await this.$api
                .consumeGeneration<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.window,
                    this.filterForm.installation.split(" - ")[0],
                    JSOON,
                    this.filterForm.comparative,
                    this.filterForm.noHijos
                )
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error;
                });

            const promises = [EnergyHorizReq];

            const GSON = JSON.parse(JSON.stringify(JSOON));

            if (this.filterForm.services) {
                if (this.filterForm.services.length > 0) {
                    try {
                        const serviceRes = await this.$api.consumeServices<
                            responseChart
                        >(
                            this.start,
                            this.end,
                            this.filterForm.window,
                            this.filterForm.comparative,
                            this.filterForm.noHijos,
                            this.filterForm.services
                        );
                        if (serviceRes.ok) {
                            if (
                                (this.filterForm.comparative && !this.first) ||
                                !this.filterForm.comparative
                            ) {
                                this.servicios1 = serviceRes.data;
                            } else {
                                this.servicios2 = serviceRes.data;
                            }
                        }
                    } catch (error) {
                        if (error instanceof Error) {
                            this.showError(error.message);
                        }
                    }

                    GSON[0]["services"] = this.filterForm.services;
                } else {
                    GSON[0]["services"] = [];
                }
            } else {
                GSON[0]["services"] = [];
            }

            if (!this.filterForm.comparative) {
                try {
                    const csvRes = await this.$api.CSVconsumos<rds>(
                        this.start,
                        this.end,
                        this.filterForm.window,
                        this.filterForm.comparative,
                        GSON
                    );
                    if (csvRes.ok) {
                        this.CSV = csvRes.data;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
            }

            Promise.allSettled(promises).then(async results => {
                await sleep(500);
                const comparitionEnergyRes = results[0];
                if (comparitionEnergyRes.status === "fulfilled") {
                    if (comparitionEnergyRes.value.ok) {
                        if (this.filterForm.comparative && !this.first) {
                            this.activeEnergyTotal2Chart =
                                comparitionEnergyRes.value.data.Consumos.pie;
                            this.consumptionTot2 =
                                comparitionEnergyRes.value.data.Consumos.stats;
                            if (this.filterForm.inversors?.length) {
                                this.generationTot2 =
                                    comparitionEnergyRes.value.data.Generacion.stats;
                                this.generation2Chart =
                                    comparitionEnergyRes.value.data.General;
                                this.generation2InversorChart =
                                    comparitionEnergyRes.value.data.Generacion.elements;
                            }
                            this.activeEnergy2Chart =
                                comparitionEnergyRes.value.data.Consumos.total;
                            this.heatmap2 =
                                comparitionEnergyRes.value.data.Consumos.heatmap;
                            this.activeEnergyComparative2Chart =
                                comparitionEnergyRes.value.data.Consumos.elements;
                            this.consumption2 =
                                comparitionEnergyRes.value.data.Consumos.table[
                                    "values"
                                ];
                            this.parc2 =
                                comparitionEnergyRes.value.data.Consumos.child;
                            this.setProgressBar(false);
                        } else {
                            this.activeEnergyTotal1Chart =
                                comparitionEnergyRes.value.data.Consumos.pie;
                            this.consumptionTot1 =
                                comparitionEnergyRes.value.data.Consumos.stats;
                            if (this.filterForm.inversors?.length) {
                                this.generationTot1 =
                                    comparitionEnergyRes.value.data.Generacion.stats;
                                this.generation1Chart =
                                    comparitionEnergyRes.value.data.General;
                                this.generation1InversorChart =
                                    comparitionEnergyRes.value.data.Generacion.elements;
                            }
                            this.activeEnergy1Chart =
                                comparitionEnergyRes.value.data.Consumos.total;
                            this.heatmap1 =
                                comparitionEnergyRes.value.data.Consumos.heatmap;
                            this.activeEnergyComparative1Chart =
                                comparitionEnergyRes.value.data.Consumos.elements;
                            this.columns =
                                comparitionEnergyRes.value.data.Consumos.table[
                                    "cols"
                                ];
                            this.consumption1 =
                                comparitionEnergyRes.value.data.Consumos.table[
                                    "values"
                                ];
                            this.parc1 =
                                comparitionEnergyRes.value.data.Consumos.child;
                        }
                        if (this.filterForm.comparative && this.first) {
                            this.setProgressBar(false);
                        }
                    } else {
                        this.errors.push({
                            chart: "Energy-activeEnergy",
                            message: comparitionEnergyRes.value.message
                        });
                        this.showError(comparitionEnergyRes.value.message);
                    }
                }
            });
            if (this.filterForm.comparative && !this.first) {
                this.start = this.start1;
                this.end = this.end1;
                this.first = true;
            } else {
                this.first = false;
            }
        } while (this.first);
        this.showAll = true;
        this.setProgressBar(false);
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    resetCSV() {
        this.CSV = "";
    }

    downloadCSV() {
        // window.location.href =
        //     window.location.origin +
        //     `/api/common/download-csv?box=irizar_e01&element=P1&start=2022-04-05&end=2022-04-06`;

        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(this.CSV);
        anchor.target = "_blank";
        anchor.download =
            this.filterForm.client.split(" - ")[1] +
            "_Consumos_" +
            this.start +
            "_" +
            this.end +
            ".csv";
        anchor.click();
    }

    formatDates() {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        const timezone = String(this.$t("timeZone"));
        if (this.filterForm.comparative) {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.start2)
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.end2)
                    )
                );
        } else {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.filterForm?.dates[0])
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.filterForm.dates[1])
                    )
                );
        }
        return "";
    }

    formatDatesComp() {
        // const options = {
        //     weekday: "long",
        //     year: "numeric",
        //     month: "long",
        //     day: "numeric"
        // };
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        const timezone = String(this.$t("timeZone"));
        if (this.filterForm.comparative) {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.start1)
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.end1)
                    )
                );
        }
        return "";
    }

    getEchart(option) {
        if (option == 0) {
            return 0;
        }
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        }
        return noData;
    }
}
